import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { TitleSection } from '../../core/models';
import { Location } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { TitleSectionComponent } from '../components/title-section/title-section.component';
@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  standalone: true,
  imports: [TitleSectionComponent],
})
export class Page404Component {
  constructor(public readonly location: Location, @Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.add('hiden-footer-subscribe');
    }
  }
  data: TitleSection = {
    name: '404 error',
    title: 'Page not found',
    descriptions:
      'We’re sorry, but the page you’re looking for could not be found. It may have been moved, deleted, or never existed in the first place.',
    isBigTitle: true,
  };
}
