<div class="container">
  <section class="section">
    <app-title-section [data]="data"></app-title-section>
    <div class="button-wrap-center">
      <div class="margin-group-right">
        <button type="button" class="btn mobile-full-button" (click)="location.back()">
          <span><- Back</span>
        </button>
      </div>
      <a href="/"><button type="button" class="btn-outline mobile-full-button">Home page</button></a>
    </div>
  </section>
</div>
